import "./bootstrap";
import "../css/app.css";
import {createInertiaApp, Link} from "@inertiajs/vue3";
import {__, getLocale, locales, setLocale, trans, transChoice} from "matice";
import {addIcons, OhVueIcon} from "oh-vue-icons";
import * as Sentry from "@sentry/vue";
import {
    FaEllipsisV,
    FaRandom,
    FaTrash,
    FaTrashRestoreAlt,
    FcAlarmClock,
    FcApproval,
    FcCalendar,
    FcCollapse,
    FcConferenceCall,
    FcCopyright,
    FcCustomerSupport,
    FcDataSheet,
    FcDeleteRow,
    FcDepartment,
    FcDiploma2,
    FcDisclaimer,
    FcDocument,
    FcEmptyTrash,
    FcExpand,
    FcFeedback,
    FcFilingCabinet,
    FcFullTrash,
    FcGraduationCap,
    FcHome,
    FcInspection,
    FcLeave,
    FcLock,
    FcPlus,
    FcQuestions,
    FcReading,
    FcRedo,
    FcRemoveImage,
    FcSettings,
    FcUnlock,
    IoCloseOutline,
    RiDeleteBack2Line,
} from "oh-vue-icons/icons";
import {createApp, h} from "vue";
import {createMetaManager} from "vue-meta";
import VueMobileDetection from "vue-mobile-detection";
import Toast, {POSITION} from "vue-toastification";
import "vue-toastification/dist/index.css";
import {resolvePageComponent} from "laravel-vite-plugin/inertia-helpers";

const dsn = import.meta.env.VITE_SENTRY_DSN_PUBLIC || null;

addIcons(
    FcHome,
    FcReading,
    FcDocument,
    FcInspection,
    FcDiploma2,
    FcConferenceCall,
    FcDepartment,
    FcFilingCabinet,
    FcApproval,
    FcGraduationCap,
    FcFeedback,
    FcCustomerSupport,
    FcCollapse,
    FcExpand,
    FcRemoveImage,
    FcDeleteRow,
    FcEmptyTrash,
    RiDeleteBack2Line,
    IoCloseOutline,
    FcUnlock,
    FcLock,
    FaRandom,
    FcCalendar,
    FcRedo,
    FcPlus,
    FcCopyright,
    FcFullTrash,
    FaTrashRestoreAlt,
    FaTrash,
    FcSettings,
    FcLeave,
    FcAlarmClock,
    FaRandom,
    FcCalendar,
    FcRedo,
    FcDataSheet,
    FcPlus,
    FcCopyright,
    FcFullTrash,
    FaTrashRestoreAlt,
    FaTrash,
    FcSettings,
    FcLeave,
    FcQuestions,
    FcDisclaimer,
    FaEllipsisV,
);

const el = document.getElementById("app");

const options = {
    position: POSITION.BOTTOM_RIGHT,
};

createInertiaApp({
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),
    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)}).mixin({
            methods: {
                route: window.route,
                $trans: trans,
                $__: __,
                $transChoice: transChoice,
                $setLocale(locale) {
                    setLocale(locale);
                    this.$forceUpdate(); // Refresh the vue instance (The whole app in case of SPA) after the locale changes.
                }, // The current locale
                $locale() {
                    return getLocale();
                }, // A listing of the available locales
                $locales() {
                    return locales();
                },
            },
        });

        Sentry.init({
            app,
            dsn: dsn,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/fts\.spirited\.dev/,
                /^https:\/\/fts\.test/,
            ],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        app.use(plugin)
            .component("InertiaLink", Link)
            .use(createMetaManager())
            .use(Toast, options)
            .use(VueMobileDetection)
            .component("v-icon", OhVueIcon)
            .mount(el);
    },
}).then();
